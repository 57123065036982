import { Component, input, output } from '@angular/core';
import { ImageViewComponent } from '../../roam-layout/image-view/image-view.component';
import { RoamIconComponent } from '../../roam-icon/roam-icon.component';

@Component({
  selector: 'user-preview-card',
  standalone: true,
  imports: [ImageViewComponent, RoamIconComponent],
  template: `
    <div class="user-preview-card">
      <div class="user-preview-header">
        <div class="align-center gap-12">
          <app-image-view [image]="''"
            width="64px"
            height="64px"
            [placeholderWidth]="'64px'"
            [placeholderHeight]="'64px'"
            [rounded]="true"
          />
          <div class="d-grid">
            <h6 class="tc-black font-16px fw-500">
              {{ name( )}}
            </h6>
            <div class="font-14px tc-grey ellipsis">
              {{ email() }}
            </div>
          </div>
        </div>
        <div>
          <ng-content select="[actRight]"></ng-content>
        </div>
      </div>

      <div class="mt-20 align-center gap-12">
        <button class="btn-base btn-view" (click)="view.emit()">View Detail</button>

        <div class="align-center gap-12">
          <button class="btn-base circle">
            <roam-icon name="mail" color="primary" size="20"/>
          </button>
          <button class="btn-base circle">
            <roam-icon name="chat" color="primary" size="20"/>
          </button>
          <button class="btn-base circle">
            <roam-icon name="phone" color="primary" size="20"/>
          </button>
        </div>
        
      </div>
    </div>
  `,
  styles: `
    .user-preview-card {
      width: 288px;
      padding: 12px 0;
    }

    .user-preview-header {
      display: flex;
      justify-content: space-between;
    }

    .btn-view {
      width: 152px;
      border-radius: 29px;
      background: rgba(145, 70, 106, 0.10);
      padding: 10px;
      cursor: pointer;
    }

    .btn-base.circle {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid rgba(145, 70, 106, 0.50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `
})
export class UserPreviewCardComponent {
  avatarUrl = input<string>();
  name = input.required<string>();
  email = input.required<string>();
  view = output();

}
